export default {
  computed: {
    list() {
      return this.$store.state.moduleSaas.list;
    },
    isLoading() {
      return this.$store.state.moduleSaas.isLoading;
    },
  },
  methods: {
    fetchList() {
      return this.$store.dispatch('moduleSaas/fetchList');
    },
  },
};
